import { FC } from "react";
import { ICON_CLASS } from "@utils/enum/icon.enum";
import Icon from "../Icon/Icon";
import { ScrollToTopProps } from "./models/scrollToTop.model";
import "./ScrollToTop.scss";
import { useTranslation } from "react-i18next";

const ScrollToTop: FC<ScrollToTopProps> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button
      tabIndex={0}
      onClick={onClick}
      className="ScrollToTop"
      aria-label={t("shared.scrollToTop")}
      title={t("shared.scrollToTop")}
    >
      <div data-testid="scroll-to-top">
        <Icon
          iconClass={ICON_CLASS.ARROW_UP}
          data-testid="scroll-to-top-button"
        />
      </div>
    </button>
  );
};

export default ScrollToTop;
