import React, { memo } from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { useTranslation } from "react-i18next";

const Footer: React.FC = (props) => {
  const { t } = useTranslation();
  return (
    <footer className="Footer">
      <div className="footer-dark" data-testid="footer">
        <div className="Footer__Container container-fluid px-0">
          <div className="Footer__Container__logos row gx-0">
            <div className="col-12 py-4">
              <ul className="list-inline d-inline-block me-3 mb-0">
                <li className="list-inline-item me-5 Footer__Container__item">
                  <img
                    src="/assets/img/footer/logo-eu_PNRR_white.svg"
                    alt={t("shared.footer.logoEUAltText")}
                    height={45}
                  />
                </li>
                <li className="list-inline-item me-5 Footer__Container__item">
                  <img
                    src="/assets/img/footer/Ministero_della_salute.svg"
                    alt={t("shared.footer.logoMinistryAltText")}
                    height={45}
                  />
                </li>
                <li className="list-inline-item">
                  <img
                    src="/assets/img/footer/DTD_w.svg"
                    alt={t("shared.footer.logoDTDAltText")}
                    height={45}
                  />
                </li>
              </ul>
            </div>
          </div>
          <div className="Footer__Container__bottomBar row gx-0">
            <div className="col-12">
              {/* <ul className="list-inline d-inline-block me-3 mb-0">
                <li className="list-inline-item">
                  <Link
                    to="#"
                    className="fw-semibold external-link"
                    target="_blank"
                  >
                    {t("shared.footer.accessibility")}
                    <span className="visually-hidden">
                      {t("shared.footer.accessibilityCTA")}
                    </span>
                  </Link>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default memo(Footer);
